import React from 'react';
import Layout from './Layout';
import Contact1 from './Contact1';


const Contact2 = () => {
    return (
        <Layout>
            <Contact1 />
        </Layout>

    );
}

export default Contact2;
