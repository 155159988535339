import React, { Fragment, useEffect, useRef } from 'react';
import Layout from './Layout';
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import CommonCarousel from './CommonCarousel';
import Treatments from './Treatments';
import Facilities from './Facilities';
import Contact1 from './Contact1';
import ContactModal from './ContactModal';
{/* <div className="modal-header">
    <h5 className="modal-title" id="exampleModalLabel">Add New Batch</h5>
    <button type="button"  onClick={() => setModalBox(false)}></button>
</div> */}
const Home = () => {
    return (
        <>
            <Layout>
                <h2 className="stylish-heading">New <span class="different-styling">Life</span> Rehabilitation & De-Addiction Centre</h2>
                <CommonCarousel pageName={'home'} />
                <Treatments />
                <Facilities />
            </Layout>

        </>
    );
}

export default Home;
