import React from 'react';
import Layout from './Layout';
import { IN } from 'country-flag-icons/react/3x2'
import CommonCarousel from './CommonCarousel';

const AboutUs = () => {
  return (
    <Layout>
      <div className='container-fluid p-0'>
        <h2 class="stylish-heading">New <span class="different-styling">Life</span> Rehabilitation & De-Addiction Centre</h2>
        <div className="row">
          <div className="col-md-4 ">
            <CommonCarousel pageName={'about'} />
          </div>
          <div className="col-md-8 p-md-0 p-4 ">
            <h5 className='text-start text-success'>Who We Are..</h5>
            <h3> <i><b>We give because we have been given much.</b></i></h3>
            <p className=''>We offers a wide range of psychotheraphy & counselling services to everyone that involves all kinds of mental health issues.
              Tell us what troubles you.</p>
            <p className='p-1'> <b>Mission :</b> <i>Fit India</i><IN width={17} className='ms-1' />
              <br />
              <b>Process :</b> Patients are got exercises and gone through the psychology classes and made them realize the importance of themselves, their family and their life so that their will power increases to overcome the addiction which helps them to escape from the darkness.
              <br />

              <b>Purpose :</b>  Drug rehabilitation is the process of medical or psychotherapeutic treatment for dependency on psychoactive substances such as alcohol, prescription drugs, and street drugs such as cannabis, cocaine, heroin or amphetamines. The general intent is to enable the patient to confront substance dependence, if present, and cease substance abuse to avoid the psychological, legal, financial, social, and physical consequences that can be caused, especially by extreme abuse.
            </p>
            <h4 className='ms-3 '> -Sheeraj Malik ( B.Tech , MA , MSW )</h4>
          </div>
          <p className='ps-3'>
            <b>Office :</b> 9627147555
            <br />
            <b>Address :</b> New Life Nasha Mukti Kendra Suhaas (Barkheda) Road , Pipariya Gajraula Kalan , Puranpur Road Pilibhit.
          </p>

        </div>

        <div className="row mt-3 p-sm-3 p-1" style={{ backgroundColor: '#f5f5f5', color: '#333' }}>
          <h2 className="text-center">Our Journey</h2>
          <hr style={{ borderColor: '#333' }} />
          <p className="text-center">The journey of New Life Nasha Mukti Kendra began in September 2020 on a considerably small scale with only 10 beds with Er. Sheeraj Malik (MA, MSW). He started this centre in Gajraula Kalan, Puranpur Road, Pilibhit, on rented premises. But in just 4 years of brilliant and hardworking persistence, the Centre was relocated to a better facility in Suhaas/Barkheda Road, Gajraula Kalan, Pilibhit. This new 20 bedded Centre kept growing and evolving.</p>
        </div>








      </div>
    </Layout>
  );
}

export default AboutUs;
