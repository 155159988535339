import React from 'react';
import { GrLocation } from "react-icons/gr";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineCopyright } from "react-icons/md";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <div className='container-fluid d-flex justify-content-center mt-2' style={{ backgroundColor: "#017e7e"}}>
                <div className="row text-light text-start" style={{ maxWidth: "1250px" }}>
                    <div className="col-sm-5 p-sm-3 p-1">
                        <h5 >About Us</h5>
                        <p>
                            We offers a wide range of psychotheraphy & counselling services to everyone that involves all kinds of mental health issues.
                            Tell us what troubles you
                        </p>
                    </div>
                    <div className="col-sm-2 p-sm-3 p-1">
                        <h5 >Quick Links</h5>
                        <ul className='no-bullets'>
                            <Link to='/' style={{color:"inherit",textDecoration:"none"}}><li>Home</li></Link>
                            <Link to='/about' style={{ color: "inherit", textDecoration: "none" }}><li>About Us</li></Link>                            
                            <Link to='/contact' style={{ color: "inherit", textDecoration: "none" }}><li>Contact</li></Link>                            
                        </ul>
                    </div>
                    <div className="col-sm-5 p-sm-3 p-1 ">
                        <h5 >Get in Touch</h5>
                        <ul className='no-bullets'>
                            <li>
                                <div className="d-flex justify-content-between ">
                                    <div className="col-1 ">
                                        <GrLocation />
                                    </div>
                                    <div className="col-11">
                                        New Life Nasha Mukti Kendra Suhaas (Barkheda) Road , Pipariya Gajraula Kalan , Puranpur Road Pilibhit.
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex justify-content-between ">
                                    <div className="col-1 ">
                                        <MdEmail />
                                    </div>
                                    <div className="col-11">
                                        newlifenashamuktikendra@gmail.com
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex justify-content-between ">
                                    <div className="col-1 ">
                                        <FaPhone />
                                    </div>
                                    <div className="col-11 ">
                                        9627147555
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>

            </div>
            <div className='container-fluid text-center p-2 bg-dark text-white'>
                <p> Copyright <MdOutlineCopyright /> 2024 | New Life Nasha Mukti Kendra- All Right Reserved</p>
               
            </div>
        </>

    );
}

export default Footer;
