import React from 'react';
import { BsLinkedin, BsGithub, BsFacebook } from "react-icons/bs";
import { FaWhatsappSquare } from "react-icons/fa"
import { FaXTwitter } from "react-icons/fa6";
import contactBanner from '../images/contact.jpg'
import './contact.css'
import { Button } from 'react-bootstrap';

const Contact1 = () => {
    return (
        <>
            <div className="card">
                <div className="card-header text-center "><h3>CONTACT US</h3></div>
                <div className="card-body">
                    <div className="row">
                        <div className="mb-0 d-block  justify-content-center ">
                            <h6 className='text-center '> <span style={{ color: '#c5b113f0' }}>Contact With</span>
                                <br /> <br />  <a href="https://www.linkedin.com/in/mohammad-raaz-b31a531b1/"><BsLinkedin size={28} className='ms-3' color='#0377fc' /></a>
                                <a href="https://github.com/Raaz902"><FaXTwitter size={28} className='ms-3' color='#000' /></a>
                                <a href="https://www.facebook.com/mohdraaz.raaz.98"><BsFacebook size={28} className='ms-3' color='#0377fc' /></a>
                                <a href="https://wa.me/919627147555?text=Hello%20from%20my%20website!"><FaWhatsappSquare size={28} className='ms-3' color='#075E54' /></a>
                            </h6>
                            <h5 className='text-center mt-2'>OR</h5>

                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor='name' className="form-label">Name</label>
                            <input id='name' type="text" className='form-control' placeholder='Pleas Eneter Your Name' />
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor='email' className="form-label">Email</label>
                            <input id='email' type="text" className='form-control' placeholder='Pleas Eneter Your Email' />
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor='email' className="form-label">Message</label>
                            <textarea id='email' type="text" className='form-control' placeholder='Pleas Eneter Your Message' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer p-3" >
                <div className="d-flex justify-content-end ">
                    <button className='btn btn-outline-success  btn-sm'>
                        Send Message
                    </button>
                </div>

            </div>
        </>
    );
}

export default Contact1;
