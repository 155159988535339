import React from 'react';
import { Carousel } from 'react-bootstrap';
import './commonStyle.css'

//home carousel images
import image1 from '../images/office.jpg'
import image2 from '../images/bedRoom.jpg'
import image3 from '../images/mainFront.jpg'
//about carousel images
import image4 from '../images/forAcoutTV.jpg'
import image5 from '../images/office.jpg'
import image6 from '../images/bedRoom.jpg'

let homeImages = [image1, image2, image3]
let aboutImages = [image4, image5, image6]

const CommonCarousel = ({ pageName }) => {
    return (
        <>
            <Carousel data-bs-theme="dark">
                {(pageName === 'about' ? aboutImages : homeImages).map(image => (
                    <Carousel.Item interval={1000} key={image}>
                        <div className=" d-flex justify-content-center ">
                            <img className="d-block " src={image} alt="imag" /* style={{ width: "100%", height: "10em" }} */ />
                        </div>
                    </Carousel.Item> 
                ))
                }
            </Carousel>
        </>
    );
}

export default CommonCarousel;
