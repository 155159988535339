import React from 'react';
import { Card } from 'react-bootstrap';
import pic1 from '../images/supportiveEnvrmnt.js.jpg'
import pic2 from '../images/bedRoom.jpg'
import pic3 from '../images/guidance.jpg'


let data = [
    {
        title: "Friendly And Supportive Environment",
        pic: pic1,
        description: "Our center provides a friendly and supportive environment where individuals feel safe and cared for throughout their recovery journey."
    },
    {
        title: "Spacious Room For Everyone",
        pic: pic2,
        description: "We offer spacious rooms equipped with modern amenities to ensure comfort and relaxation for everyone seeking recovery."
    },
    {
        title: "Proper Guidance",
        pic: pic3,
        description: "Our experienced staff provides proper guidance and support to individuals, helping them navigate challenges and achieve successful recovery outcomes."
    }
];

const Facilities = () => {
    return (
        <>
            <h2 className='text-center mt-4 pb-0 mb-0'>Our Facilities</h2>
            <p className='text-center text-success'> <i><b>“Your Journey to Recovery Starts at New Life”</b></i></p>
            <div className="container-fluid">
                <div className="row p-md-3 p-sm-2 mt-4">
                    {data.map(item => (
                        <div key={item.title} className="col-lg-4 col-md-4 col-sm-6 mt-4">
                            <Card className="custom-card">
                                <Card.Img variant="top" src={item.pic} className="custom-card-img" />
                                <Card.Body className="custom-card-body">
                                    <Card.Title className="custom-card-title">{item.title}</Card.Title>
                                    <Card.Text className="custom-card-text">
                                        {item.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}

                </div>
            </div>

          
        </>
    );
}

export default Facilities;
