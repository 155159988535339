import React from 'react';
import { Card } from 'react-bootstrap';
import Image1 from '../images/First_treat.jpg'
import Image2 from '../images/alchohol.jpeg'
import Image3 from '../images/drug.jpeg'
import Image4 from '../images/Family.jpeg'
import Image5 from '../images/mental.jpeg'
import Image6 from '../images/neu.jpeg'


let data = [
    {
        title: "Addiction recovery",
        pic: Image1,
        description: "Overcoming addiction involves facing challenges and receiving support to reclaim life."
    },
    {
        title: "Alcoholism recovery",
        pic: Image2,
        description: "Achieving sobriety and wellness through specialized programs and support."
    },
    {
        title: "Drug abuse recovery",
        pic: Image3,
        description: "Rebuilding lives with purpose and resilience after drug abuse."
    },
    {
        title: "Family support services",
        pic: Image4,
        description: "Providing education, counseling, and resources for families affected by addiction."
    },
    {
        title: "Mental health",
        pic: Image5,
        description: "Addressing mental health alongside addiction for holistic care and support."
    },
    {
        title: "Neurological Rehabilitation",
        pic: Image6,
        description: "Restoring function and independence after injury or illness."
    }
];


const Treatments = () => {
    return (
        <>
        <div className="container-fluid">
                <h2 className='text-center mt-4 pb-0 mb-0'>Our Treatments</h2>
                <p className='text-center text-success'> <i><b>“We give because we have been given much”</b></i></p>
                <div className="row m-md-3 m-sm-2">
                    {data.map(item => (
                        <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
                            <Card key={item.title} className="custom-card">
                                <Card.Img variant="top" src={item.pic} className="custom-card-img" />
                                <Card.Body className="custom-card-body">
                                    <Card.Title className="custom-card-title">{item.title}</Card.Title>
                                    <Card.Text className="custom-card-text">
                                        {item.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}

                </div>
        </div>

          
        </>
    );
}

export default Treatments;
